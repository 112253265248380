<template>
	<v-dialog fullscreen persistent :value="dialog">
		<v-card>
			<v-toolbar dark color="primary">
				<v-toolbar-title v-text="dialogTitle" />
				<v-spacer></v-spacer>
				<v-btn icon @click="closeDialog()">
					<v-icon>close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-form v-model="isFormValid">
				<w-section :title="$t('sa.applications.labels.general_informations')">
					<v-layout row wrap>
						<v-flex pr-2 xs4>
							<w-image-input
								v-model="application.logo"
								accept="image/gif,image/jpeg,image/png,image/svg+xml"
								:label="$t('sa.applications.labels.logo')"
								required
								style="height: 272px; margin: auto; max-height: 272px"
							/>
						</v-flex>
						<v-flex pl-2 xs8>
							<v-layout column>
								<w-text-input v-model="application.title" :label="$t('sa.applications.labels.title')" maxlength="191" required />
								<w-text-input v-model="application.baseline" :label="$t('sa.applications.labels.baseline')" maxlength="191" />
								<w-text-input v-model="application.description" :label="$t('sa.applications.labels.description')" maxlength="75" />
								<w-text-input v-model="application.type" :label="$t('sa.applications.labels.type')" maxlength="191" required />
							</v-layout>
						</v-flex>
						<v-flex my-2 xs12>
							<w-rich-text-editor
								v-model="application.description_long"
								:label="$t('sa.applications.labels.description_long')"
								mode="advanced"
								required
								style="max-height: 300px; overflow-y: scroll"
							></w-rich-text-editor>
						</v-flex>
						<v-flex pr-2 xs4>
							<w-text-input v-model="application.site_url" :label="$t('sa.applications.labels.site_url')" required type="url" />
						</v-flex>
						<v-flex px-2 xs4>
							<v-text-field v-model="application.uri" :label="$t('sa.applications.labels.uri')" type="url" />
						</v-flex>
						<v-flex pl-2 xs4>
							<w-text-input v-model="application.video" :label="$t('sa.applications.labels.video')" type="url" />
						</v-flex>
						<v-flex pr-2 xs6>
							<v-textarea v-model="application.price_txt" color="primary" :label="$t('sa.applications.labels.price_txt')"></v-textarea>
						</v-flex>
						<v-flex pl-2 xs6>
							<w-image-input
								v-model="application.price_image"
								accept="image/gif,image/jpeg,image/png,image/svg+xml"
								:label="$t('sa.applications.labels.price_img')"
								style="margin: auto; min-height: 200px; height: 200px; min-width: 500px"
							/>
						</v-flex>
					</v-layout>
				</w-section>
				<w-section :title="$t('sa.applications.labels.application_settings')">
					<v-flex xs12>
						<w-text-input v-model="application.params_accounting_firm" :label="$t('sa.applications.labels.params_accounting_firm')" />
					</v-flex>
					<v-flex xs12>
						<w-text-input v-model="application.params_vendor" :label="$t('sa.applications.labels.params_vendor')" />
					</v-flex>
					<v-layout row wrap>
						<v-flex xs4>
							<w-switch v-model="application.is_active" :label="$t('sa.applications.labels.is_active')" />
						</v-flex>
						<v-flex xs4>
							<w-switch v-model="application.is_automation" :label="$t('sa.applications.labels.is_automation')" @input="onIsAutomationChanged($event)" />
						</v-flex>
						<v-flex xs4>
							<w-switch v-model="application.is_home" :label="$t('sa.applications.labels.is_home')" />
						</v-flex>
						<v-flex xs4>
							<w-switch :value="!application.is_private" :label="$t('sa.applications.labels.is_private')" @input="application.is_private = !$event" />
						</v-flex>
					</v-layout>
					<v-layout mt-2 row wrap>
						<v-flex v-if="application.is_automation" pr-1 xs6>
							<w-select
								v-model="application.automation_type_id"
								:items="automationTypes"
								:label="$t('sa.applications.labels.automation_type')"
								:loading="automationTypesLoading"
								item-text="label"
								item-value="id"
								required
							/>
						</v-flex>
						<v-flex v-if="isMailAutomation" px-1>
							<w-email-input 
								v-model="application.email_recipient" 
								:label="$t('sa.applications.labels.email_recipient')"
								:required="false" 
							/>
						</v-flex>
						<v-flex v-if="isMailAutomation" pl-1 style="max-width:200px">
							<w-number-input
								v-model="application.mail_attachement_max_size"
								:label="$t('sa.applications.labels.mail_attachment_max_size')"
								min="0"
								suffix="Mo"
							></w-number-input>
						</v-flex>
					</v-layout>
				</w-section>
			</v-form>
			<v-layout row>
				<v-spacer />
				<w-btn-save :disabled="!isValid || !isDirty" :loading="saveLoading" @click="saveApplication" />
			</v-layout>
		</v-card>
	</v-dialog>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'ApplicationForm',
	mixins: [SuperAdminModuleGuard],
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.previousRoute= from
		})
	},
	props: {
		value: {
			required: true,
			type: [Number, String]
		}
	},
	data: function () {
		return {
			application: {},
			automationTypes: [],
			automationTypesLoading: false,
			dialog: false,
			isFormValid: false,
			originalApplication: null,
			previousRoute: null,
			saveLoading: false
		}
	},
	computed: {
		isValid: function () {
			return this.isFormValid && this.application?.logo
		},
		applicationData: function () {
			if (!this.value) {
				return this.application
			}

			const result = {}
			for (const [key, value] of Object.entries(this.application)) {
				if (value != this.originalApplication[key]) {
					result[key] = value
				}
			}

			return result
		},
		dialogTitle: function () {
			let result = null

			if (this.originalApplication?.title) {
				result = this.originalApplication.title
			} else {
				result = this.$t('sa.applications.labels.creation_form_title')
			}

			return result
		},
		isDirty: function () {
			return Object.values(this.applicationData).length > 0
		},
		isMailAutomation: function () {
			const type = this.automationTypes.find(type => type.id == this.application.automation_type_id)

			return type?.name == 'smtp'
		}
	},
	watch: {
		isMailAutomation(val) {
			if (!val) {
				this.resetMailAutomationProperties();
			}
		},
		value: {
			handler: 'loadApplication'
		}
	},
	mounted: function () {
		this.dialog = true
		this.loadApplication()
		this.loadAutomationTypes()
	},
	methods: {
		closeDialog: function () {
			this.dialog = false
			if (this.previousRoute?.name == 'sa-applications-list') {
				this.appService.goTo({...this.previousRoute, replace: true})
			} else {
				this.appService.goTo('sa-applications-list')
			}
		},
		createApplication: function () {
			let formData = new FormData()

			for (const [key, value] of Object.entries(this.applicationData)) {
				if (value) {
					formData.append(key, value)
				}
			}

			return this.service.createApplication(formData).then(() => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.applications.application_added'))
			})
		},
		loadApplication: function () {
			if (!this.value) {
				this.application = {
					automation_type_id: null,
					is_active: true,
					is_automation: false,
					is_home: true,
					is_private: false,
					mail_attachement_max_size: null
				}
				return Promise.resolve()
			} else {
				return this.service.getApplication(this.value).then(application => {
					if (application.params_accounting_firm) {
						application.params_accounting_firm = JSON.stringify(application.params_accounting_firm)
					}
					if (application.params_vendor) {
						application.params_vendor = JSON.stringify(application.params_vendor)
					}
					this.application = application
					this.originalApplication = { ...application }
				})
			}
		},
		loadAutomationTypes: function () {
			this.automationTypesLoading = true
			this.service
				.getAutomationTypes()
				.then(automationsType => {
					this.automationTypes = automationsType
				})
				.finally(() => {
					this.automationTypesLoading = false
				})
		},
		onIsAutomationChanged: function (value) {
			if (!value) {
				this.application.automation_type_id = null
				this.application.mail_attachement_max_size = null
			}
		},
		resetMailAutomationProperties: function () {
			this.application.email_recipient = null
			this.application.mail_attachement_max_size = null
		},
		saveApplication: function () {
			this.saveLoading = true

			let promise
			if (this.value) {
				promise = this.updateApplication()
			} else {
				promise = this.createApplication()
			}

			promise
				.then(() => {
					this.closeDialog()
				})
				.finally(() => {
					this.saveLoading = false
				})
		},
		updateApplication: function () {
			let formData = new FormData()

			for (const [key, value] of Object.entries(this.applicationData)) {
				formData.append(key, value)
			}

			return this.service.updateApplication(this.value, formData).then(() => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.applications.application_updated'))
			})
		}
	}
}
</script>
